import React, { Component } from 'react';

import { withFirebase } from '../../../utils/Firebase';
import { AuthUserContext } from '../../../utils/Session';

import PasswordChangeForm from '../../molecules/PasswordChangeForm/password-change-form';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChange extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { passwordOne } = this.state;
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.props.firebase.useAnalytics('password_changed', {
          user: this.context.uid,
        });
        this.setState({ ...INITIAL_STATE });
        this.props.createMessage({
          type: 'success',
          title: 'Change Password',
          message: 'Your password has been changed successfully!',
        });
      })
      .catch((error) => {
        this.props.createMessage({
          type: 'warning',
          title: 'Change Password',
          message:
            'We encountered an issue while changing your password, please retry momentarily.',
        });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo } = this.state;
    const { className } = this.props;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

    return (
      <PasswordChangeForm
        className={className}
        passwordOne={passwordOne}
        passwordTwo={passwordTwo}
        isInvalid={isInvalid}
        onSubmit={(e) => this.onSubmit(e)}
        onChange={(e) => this.onChange(e)}
      />
    );
  }
}

PasswordChange.contextType = AuthUserContext;

export default withFirebase(PasswordChange);
