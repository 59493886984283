import React from 'react';

import Input from '../../atoms/Input/input';
import Button from '../../atoms/Button/button';

import './profile-form.scss';

export default ({
  values,
  dirty,
  touched,
  errors,
  isValid,
  handleBlur,
  handleChange,
  onSubmit,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <Input
        name="firstName"
        placeHolder="First Name"
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors.firstName && touched.firstName && (
        <p className="error-message">{errors.firstName}</p>
      )}
      <Input
        name="lastName"
        placeHolder="Last Name"
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors.lastName && touched.lastName && (
        <p className="error-message">{errors.lastName}</p>
      )}
      <Input
        name="phoneNumber"
        placeHolder="Phone Number"
        value={values.phoneNumber}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors.phoneNumber && touched.phoneNumber && (
        <p className="error-message">{errors.phoneNumber}</p>
      )}
      <Input
        name="companyName"
        placeHolder="Company Name"
        value={values.companyName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors.companyName && touched.companyName && (
        <p className="error-message">{errors.companyName}</p>
      )}
      <div style={{ marginTop: '1rem' }}>
        <Button
          disabled={!isValid || !dirty}
          type="submit"
          text="Update Profile"
        />
      </div>
    </form>
  );
};
