import React, { Fragment, useContext } from 'react';

import { AuthUserContext } from '../../../utils/Session';

import MiniDrawer from '../../molecules/MiniDrawer/mini-drawer';

import './dashboard-template.scss';

const DashboardTemplate = ({ children }) => {
  const authUser = useContext(AuthUserContext);

  return (
    <Fragment>
      <div className="gradient-bar" />
      <MiniDrawer authUser={authUser} />
      <main className="app-container">{children}</main>
    </Fragment>
  );
};

export default DashboardTemplate;
