import React from 'react';
import Input from '../../atoms/Input/input';
import Button from '../../atoms/Button/button';

const PasswordChangeForm = ({
  isInvalid,
  passwordOne,
  passwordTwo,
  onSubmit,
  onChange,
  className,
}) => {
  return (
    <form className={className} onSubmit={(e) => onSubmit(e)}>
      <Input
        name="passwordOne"
        value={passwordOne}
        onChange={(e) => onChange(e)}
        type="password"
        labelName="New Password"
        required
      />
      <Input
        name="passwordTwo"
        value={passwordTwo}
        onChange={(e) => onChange(e)}
        type="password"
        labelName="Confirm New Password"
        required
      />
      <div style={{ marginTop: '1rem' }}>
        <Button disabled={isInvalid} type="submit" text="Change Password" />
      </div>
    </form>
  );
};

export default PasswordChangeForm;
