import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { AuthUserContext } from '../../../utils/Session';

import ProfileForm from '../../molecules/ProfileForm/profile-form';

// eslint-disable-next-line
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

const profileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'First name must at least be 2 characters')
    .max(30, 'First name can not be more than 30 characters')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Last name must at least be 2 characters')
    .max(30, 'Last name can not be more than 30 characters')
    .required('Required'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Your phone number is invalid')
    .required('Required'),
  companyName: Yup.string().required('Required'),
});

export default ({ firebase, buyerInfo, createMessage }) => {
  const authUser = useContext(AuthUserContext);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: buyerInfo,
    validationSchema: profileSchema,
    onSubmit: (values) =>
      authUser && firebase.updateBuyerInfo(authUser, values, createMessage),
  });

  return (
    <div>
      <ProfileForm
        values={formik.values}
        dirty={formik.dirty}
        touched={formik.touched}
        errors={formik.errors}
        isValid={formik.isValid}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        onSubmit={formik.handleSubmit}
      />
    </div>
  );
};
