import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../utils/Session';

import Layout from '../utils/layout';
import Account from '../components/scenes/Account/account';

const condition = (authUser) => !!authUser;

const AccountPage = compose(withAuthorization(condition))(Account);

export default () => (
  <Layout>
    <AccountPage />
  </Layout>
);
