import React from 'react';
import classNames from 'classnames';

import Button from '../../atoms/Button/button';

import './logout-modal.scss';

export default ({ modalIsActive, closeModal, firebase }) => {
  return (
    <div
      className={classNames('modal', {
        'is-active': modalIsActive,
      })}
    >
      <div className="modal-background" />
      <div className="modal-content logout-modal">
        <p className="logout-modal__content-paragraph">
          Are you sure you want to log out?
        </p>
        <div className="logout-modal__content-action">
          <span>
            <Button text="No" className="btn-primary" onClick={closeModal} />
          </span>
          <span>
            <Button
              text="Yes"
              className="btn-danger"
              onClick={() => firebase.doSignOut()}
            />
          </span>
        </div>
      </div>
    </div>
  );
};
