import React from 'react';

import { AuthUserContext } from '../../../utils/Session';

import DashboardTemplate from '../../organisms/DashboardTemplate/dashboard-template';
import ResponseHanlder from '../../molecules/ResponseHandler/response-handler';

import Button from '../../atoms/Button/button';
import PasswordChange from '../../organisms/PasswordChange/password-change';
import UpdateProfile from '../../organisms/UpdateProfile/update-profile';

import LogoutModal from '../../molecules/LogoutModal/logout-modal';
// Styles
import './account.scss';

class Account extends React.Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      buyerInfo: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        companyName: '',
      },
      logoutModal: false,
      response: undefined,
    };

    this.openLogoutModal = this.openLogoutModal.bind(this);
    this.closeLogoutModal = this.closeLogoutModal.bind(this);
    this.createMessage = this.createMessage.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.setBuyerInfo = this.setBuyerInfo.bind(this);
    this.getBuyerInfo = this.getBuyerInfo.bind(this);
  }

  openLogoutModal() {
    return this.setState({ logoutModal: true });
  }
  closeLogoutModal() {
    return this.setState({ logoutModal: false });
  }

  createMessage({ type, title, message, linkPath, linkText }) {
    return this.setState({
      response: { type, title, message, linkPath, linkText },
    });
  }

  deleteMessage() {
    return this.setState({ response: undefined });
  }

  setBuyerInfo(buyerInfo) {
    return this.setState({ buyerInfo });
  }

  getBuyerInfo() {
    return (
      this.context && this.props.firebase.buyer(this.context, this.setBuyerInfo)
    );
  }

  componentDidMount() {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      this.unsubscribe = this.getBuyerInfo();
    }
  }

  componentDidUpdate() {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      this.unsubscribe = this.getBuyerInfo();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  render() {
    const { buyerInfo, logoutModal, response } = this.state;
    const { firebase } = this.props;

    return (
      <DashboardTemplate>
        {response && (
          <ResponseHanlder
            response={response}
            deleteMessage={this.deleteMessage}
          />
        )}
        <div className="settings-container">
          <div>
            <p>Account: {buyerInfo && buyerInfo.email}</p>
            <div className="settings-container__form-container">
              <UpdateProfile
                firebase={firebase}
                buyerInfo={buyerInfo}
                createMessage={this.createMessage}
              />
            </div>
            <div className="settings-container__form-container">
              <PasswordChange
                className="custom-settings__password-change"
                createMessage={this.createMessage}
              />
            </div>
            <div className="settings-container__form-container">
              <Button
                text="Logout"
                className="btn-danger"
                onClick={this.openLogoutModal}
              />
            </div>
          </div>
        </div>
        <LogoutModal
          firebase={firebase}
          modalIsActive={logoutModal}
          closeModal={this.closeLogoutModal}
        />
      </DashboardTemplate>
    );
  }
}

Account.contextType = AuthUserContext;

export default Account;
