import React from 'react';
import { Link } from 'gatsby';

import { INVENTORIES, ACCOUNT, CART, SIGN_IN } from '../../../constants/routes';

import Logo from '../../../assets/icon.png';

import './mini-drawer.scss';

export default ({ authUser }) => {
  const supplier =
    typeof window !== 'undefined' && localStorage.getItem('supplier');
  const inventoriesLink = INVENTORIES + (supplier ? `/${supplier}` : '');

  return (
    <div>
      <div className="mini-drawer">
        <div className="mini-drawer__inner">
          <div className="mini-drawer__logo">
            <Link to={inventoriesLink}>
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="mini-drawer__link">
            <Link to={inventoriesLink} activeStyle={{ color: '#2549e7' }}>
              <i className="fas fa-shopping-basket"></i>
              <p>Market</p>
            </Link>
          </div>
          <div className="mini-drawer__link">
            <Link
              to={
                authUser
                  ? CART
                  : `${SIGN_IN}/?message=You need to login or signup before viewing the cart page&redirectURL=${CART}`
              }
              activeStyle={{ color: '#2549e7' }}
            >
              <i className="fas fa-shopping-cart"></i>
              <p>Cart</p>
            </Link>
          </div>
          <div className="mini-drawer__link">
            <Link
              to={
                authUser
                  ? ACCOUNT
                  : `${SIGN_IN}/?message=You need to login or signup before viewing the account page&redirectURL=${ACCOUNT}`
              }
              activeStyle={{ color: '#2549e7' }}
            >
              <i className="fas fa-user-circle"></i>
              <p>Account</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
